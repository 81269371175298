<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Cores Produto - Inclusão</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'productcolors'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>
    <form-product-color :btnLabel="btnLabel" :product_id="product_id"></form-product-color>
</span>
</template>

<script>
import FormProductColorComponent from "./partial/FormProductColorComponent";

export default {
    name: "AddProductColorComponent",
    created() {

    },
    data() {
        return {
            btnLabel: 'Incluir'
        }
    },
    props: {
        prod_co_id: {
            require: true
        },
        product_id: {
            require: true
        }
    },
    methods: {

    },
    components: {
        formProductColor: FormProductColorComponent,
    }
};
</script>

<style scoped>

</style>
